export const SAccountControl = () => import('../../components/SAccountControl.vue' /* webpackChunkName: "components/s-account-control" */).then(c => wrapFunctional(c.default || c))
export const SAccountControlCgu = () => import('../../components/SAccountControlCgu.vue' /* webpackChunkName: "components/s-account-control-cgu" */).then(c => wrapFunctional(c.default || c))
export const SAccountControlEmail = () => import('../../components/SAccountControlEmail.vue' /* webpackChunkName: "components/s-account-control-email" */).then(c => wrapFunctional(c.default || c))
export const SAccountControlPseudo = () => import('../../components/SAccountControlPseudo.vue' /* webpackChunkName: "components/s-account-control-pseudo" */).then(c => wrapFunctional(c.default || c))
export const SAccountControlRegister = () => import('../../components/SAccountControlRegister.vue' /* webpackChunkName: "components/s-account-control-register" */).then(c => wrapFunctional(c.default || c))
export const SAccountControlRs = () => import('../../components/SAccountControlRs.vue' /* webpackChunkName: "components/s-account-control-rs" */).then(c => wrapFunctional(c.default || c))
export const SAdSense = () => import('../../components/SAdSense.vue' /* webpackChunkName: "components/s-ad-sense" */).then(c => wrapFunctional(c.default || c))
export const SAddFormMap = () => import('../../components/SAddFormMap.vue' /* webpackChunkName: "components/s-add-form-map" */).then(c => wrapFunctional(c.default || c))
export const SCompteEmailForm = () => import('../../components/SCompteEmailForm.vue' /* webpackChunkName: "components/s-compte-email-form" */).then(c => wrapFunctional(c.default || c))
export const SCompteNotifForm = () => import('../../components/SCompteNotifForm.vue' /* webpackChunkName: "components/s-compte-notif-form" */).then(c => wrapFunctional(c.default || c))
export const SComptePasswordForm = () => import('../../components/SComptePasswordForm.vue' /* webpackChunkName: "components/s-compte-password-form" */).then(c => wrapFunctional(c.default || c))
export const SComptePseudoForm = () => import('../../components/SComptePseudoForm.vue' /* webpackChunkName: "components/s-compte-pseudo-form" */).then(c => wrapFunctional(c.default || c))
export const SCompteSettings = () => import('../../components/SCompteSettings.vue' /* webpackChunkName: "components/s-compte-settings" */).then(c => wrapFunctional(c.default || c))
export const SContextualList = () => import('../../components/SContextualList.vue' /* webpackChunkName: "components/s-contextual-list" */).then(c => wrapFunctional(c.default || c))
export const SDeleteContribution = () => import('../../components/SDeleteContribution.vue' /* webpackChunkName: "components/s-delete-contribution" */).then(c => wrapFunctional(c.default || c))
export const SDetailCarousel = () => import('../../components/SDetailCarousel.vue' /* webpackChunkName: "components/s-detail-carousel" */).then(c => wrapFunctional(c.default || c))
export const SDetailComment = () => import('../../components/SDetailComment.vue' /* webpackChunkName: "components/s-detail-comment" */).then(c => wrapFunctional(c.default || c))
export const SDetailCommentForm = () => import('../../components/SDetailCommentForm.vue' /* webpackChunkName: "components/s-detail-comment-form" */).then(c => wrapFunctional(c.default || c))
export const SDetailContributors = () => import('../../components/SDetailContributors.vue' /* webpackChunkName: "components/s-detail-contributors" */).then(c => wrapFunctional(c.default || c))
export const SDetailDescription = () => import('../../components/SDetailDescription.vue' /* webpackChunkName: "components/s-detail-description" */).then(c => wrapFunctional(c.default || c))
export const SDetailFacilities = () => import('../../components/SDetailFacilities.vue' /* webpackChunkName: "components/s-detail-facilities" */).then(c => wrapFunctional(c.default || c))
export const SDetailItem = () => import('../../components/SDetailItem.vue' /* webpackChunkName: "components/s-detail-item" */).then(c => wrapFunctional(c.default || c))
export const SDetailMap = () => import('../../components/SDetailMap.vue' /* webpackChunkName: "components/s-detail-map" */).then(c => wrapFunctional(c.default || c))
export const SDetailScheduleForm = () => import('../../components/SDetailScheduleForm.vue' /* webpackChunkName: "components/s-detail-schedule-form" */).then(c => wrapFunctional(c.default || c))
export const SDetailStreet = () => import('../../components/SDetailStreet.vue' /* webpackChunkName: "components/s-detail-street" */).then(c => wrapFunctional(c.default || c))
export const SDialogBox = () => import('../../components/SDialogBox.vue' /* webpackChunkName: "components/s-dialog-box" */).then(c => wrapFunctional(c.default || c))
export const SDialogBoxAlert = () => import('../../components/SDialogBoxAlert.vue' /* webpackChunkName: "components/s-dialog-box-alert" */).then(c => wrapFunctional(c.default || c))
export const SDialogBoxConfirm = () => import('../../components/SDialogBoxConfirm.vue' /* webpackChunkName: "components/s-dialog-box-confirm" */).then(c => wrapFunctional(c.default || c))
export const SDialogBoxFullscreen = () => import('../../components/SDialogBoxFullscreen.vue' /* webpackChunkName: "components/s-dialog-box-fullscreen" */).then(c => wrapFunctional(c.default || c))
export const SDialogBoxPicture = () => import('../../components/SDialogBoxPicture.vue' /* webpackChunkName: "components/s-dialog-box-picture" */).then(c => wrapFunctional(c.default || c))
export const SDialogBoxSimple = () => import('../../components/SDialogBoxSimple.vue' /* webpackChunkName: "components/s-dialog-box-simple" */).then(c => wrapFunctional(c.default || c))
export const SDynamicScroller = () => import('../../components/SDynamicScroller.vue' /* webpackChunkName: "components/s-dynamic-scroller" */).then(c => wrapFunctional(c.default || c))
export const SDynamicScrollerItem = () => import('../../components/SDynamicScrollerItem.vue' /* webpackChunkName: "components/s-dynamic-scroller-item" */).then(c => wrapFunctional(c.default || c))
export const SErrorState = () => import('../../components/SErrorState.vue' /* webpackChunkName: "components/s-error-state" */).then(c => wrapFunctional(c.default || c))
export const SFormLogin = () => import('../../components/SFormLogin.vue' /* webpackChunkName: "components/s-form-login" */).then(c => wrapFunctional(c.default || c))
export const SGeoMapbox = () => import('../../components/SGeoMapbox.vue' /* webpackChunkName: "components/s-geo-mapbox" */).then(c => wrapFunctional(c.default || c))
export const SImageCropper = () => import('../../components/SImageCropper.vue' /* webpackChunkName: "components/s-image-cropper" */).then(c => wrapFunctional(c.default || c))
export const SImageLoader = () => import('../../components/SImageLoader.vue' /* webpackChunkName: "components/s-image-loader" */).then(c => wrapFunctional(c.default || c))
export const SListItem = () => import('../../components/SListItem.vue' /* webpackChunkName: "components/s-list-item" */).then(c => wrapFunctional(c.default || c))
export const SLoadingState = () => import('../../components/SLoadingState.vue' /* webpackChunkName: "components/s-loading-state" */).then(c => wrapFunctional(c.default || c))
export const SLocInput = () => import('../../components/SLocInput.vue' /* webpackChunkName: "components/s-loc-input" */).then(c => wrapFunctional(c.default || c))
export const SLocalisationWrapper = () => import('../../components/SLocalisationWrapper.vue' /* webpackChunkName: "components/s-localisation-wrapper" */).then(c => wrapFunctional(c.default || c))
export const SMicrodatas = () => import('../../components/SMicrodatas.vue' /* webpackChunkName: "components/s-microdatas" */).then(c => wrapFunctional(c.default || c))
export const SNoResult = () => import('../../components/SNoResult.vue' /* webpackChunkName: "components/s-no-result" */).then(c => wrapFunctional(c.default || c))
export const SPassword = () => import('../../components/SPassword.vue' /* webpackChunkName: "components/s-password" */).then(c => wrapFunctional(c.default || c))
export const SPlaygroundMicrodatas = () => import('../../components/SPlaygroundMicrodatas.vue' /* webpackChunkName: "components/s-playground-microdatas" */).then(c => wrapFunctional(c.default || c))
export const SReadMore = () => import('../../components/SReadMore.vue' /* webpackChunkName: "components/s-read-more" */).then(c => wrapFunctional(c.default || c))
export const SResultListView = () => import('../../components/SResultListView.vue' /* webpackChunkName: "components/s-result-list-view" */).then(c => wrapFunctional(c.default || c))
export const SResultMap = () => import('../../components/SResultMap.vue' /* webpackChunkName: "components/s-result-map" */).then(c => wrapFunctional(c.default || c))
export const SResultTitle = () => import('../../components/SResultTitle.vue' /* webpackChunkName: "components/s-result-title" */).then(c => wrapFunctional(c.default || c))
export const SSchedule = () => import('../../components/SSchedule.vue' /* webpackChunkName: "components/s-schedule" */).then(c => wrapFunctional(c.default || c))
export const SScheduleGlobal = () => import('../../components/SScheduleGlobal.vue' /* webpackChunkName: "components/s-schedule-global" */).then(c => wrapFunctional(c.default || c))
export const SScheduleMonths = () => import('../../components/SScheduleMonths.vue' /* webpackChunkName: "components/s-schedule-months" */).then(c => wrapFunctional(c.default || c))
export const SSchedulePeriods = () => import('../../components/SSchedulePeriods.vue' /* webpackChunkName: "components/s-schedule-periods" */).then(c => wrapFunctional(c.default || c))
export const SSchedulePreview = () => import('../../components/SSchedulePreview.vue' /* webpackChunkName: "components/s-schedule-preview" */).then(c => wrapFunctional(c.default || c))
export const SScheduleWeek = () => import('../../components/SScheduleWeek.vue' /* webpackChunkName: "components/s-schedule-week" */).then(c => wrapFunctional(c.default || c))
export const SScrollBox = () => import('../../components/SScrollBox.vue' /* webpackChunkName: "components/s-scroll-box" */).then(c => wrapFunctional(c.default || c))
export const SSearchForm = () => import('../../components/SSearchForm.vue' /* webpackChunkName: "components/s-search-form" */).then(c => wrapFunctional(c.default || c))
export const SSideMenu = () => import('../../components/SSideMenu.vue' /* webpackChunkName: "components/s-side-menu" */).then(c => wrapFunctional(c.default || c))
export const SSnackBar = () => import('../../components/SSnackBar.vue' /* webpackChunkName: "components/s-snack-bar" */).then(c => wrapFunctional(c.default || c))
export const SSsrLink = () => import('../../components/SSsrLink.vue' /* webpackChunkName: "components/s-ssr-link" */).then(c => wrapFunctional(c.default || c))
export const SSwiper = () => import('../../components/SSwiper.vue' /* webpackChunkName: "components/s-swiper" */).then(c => wrapFunctional(c.default || c))
export const SSwiperElement = () => import('../../components/SSwiperElement.vue' /* webpackChunkName: "components/s-swiper-element" */).then(c => wrapFunctional(c.default || c))
export const STest = () => import('../../components/STest.vue' /* webpackChunkName: "components/s-test" */).then(c => wrapFunctional(c.default || c))
export const SToolbar = () => import('../../components/SToolbar.vue' /* webpackChunkName: "components/s-toolbar" */).then(c => wrapFunctional(c.default || c))
export const STooltip = () => import('../../components/STooltip.vue' /* webpackChunkName: "components/s-tooltip" */).then(c => wrapFunctional(c.default || c))
export const SUploadImage = () => import('../../components/SUploadImage.vue' /* webpackChunkName: "components/s-upload-image" */).then(c => wrapFunctional(c.default || c))
export const SUploadMultiple = () => import('../../components/SUploadMultiple.vue' /* webpackChunkName: "components/s-upload-multiple" */).then(c => wrapFunctional(c.default || c))
export const SUploadMultipleItem = () => import('../../components/SUploadMultipleItem.vue' /* webpackChunkName: "components/s-upload-multiple-item" */).then(c => wrapFunctional(c.default || c))
export const SZoomImage = () => import('../../components/SZoomImage.vue' /* webpackChunkName: "components/s-zoom-image" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
