
import { mapGetters } from 'vuex';

import ResizeObserver from 'resize-observer-polyfill';

export default {
  name: 'SScrollBox',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    global: {
      type: Boolean,
      default: false,
    },
    hasVerticalScroll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      container: null,
      scrollBox: null,
      containerIsScrolling: false,
      resizeObserverContent: null,
      resizeObserverScrollBox: null,
      isScrollable: true,
      isToolbarHideable: false,
      topBounce: false,
      bottomBounce: false,
    };
  },
  computed: {
    ...mapGetters({
      getWindowHeight: 'screen/getWindowHeight',
      getToolbarSize: 'screen/getToolbarSize',
    }),
  },
  watch: {
    disabled: {
      immediate: true,
      async handler(value) {
        if (process.server) return;
        if (value) {
          this.destroy();
          this.$emit('update:has-vertical-scroll', false);
          this.isScrollable = true;
        } else {
          await this.$nextTick();
          this.container = this.$refs.container;
          this.init();
          this.setShadows();
        }
      },
    },
    getWindowHeight() {
      this.setShadows();
      if (this.scrollBox) {
        this.checkScrollBoxSize();
      }
    },
  },
  methods: {
    init() {
      if (!this.container) return;
      this.scrollBox = this.container.querySelector('.scroll-box');
      this.content = this.scrollBox.firstElementChild;
      this.scrollBox.addEventListener('scroll', (e) => this.onScroll(e));

      this.$once('hook:beforeDestroy', () => {
        this.destroy();
      });

      this.checkScrollBoxSize();
      if (this.scrollBox) {
        this.resizeObserverScrollBox = new ResizeObserver(() => this.checkScrollBoxSize());
        this.resizeObserverScrollBox.observe(this.scrollBox);
      }

      if (!this.disabled && this.global) {
        this.checkVerticalScroll();
        if (this.content) {
          this.resizeObserverContent = new ResizeObserver(() => this.checkVerticalScroll());
          this.resizeObserverContent.observe(this.content);
        }
      }
      this.$emit('ready');
    },
    destroy() {
      this.scrollBox?.removeEventListener('scroll', (e) => this.onScroll(e));
      if (this.resizeObserverContent && this.content) {
        this.resizeObserverContent.unobserve(this.content);
      }
      if (this.resizeObserverScrollBox && this.scrollBox) {
        this.resizeObserverScrollBox.unobserve(this.scrollBox);
      }
    },
    onScroll(e) {
      if (this.isToolbarHideable) {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const atTop = scrollTop <= 0;
        const atBottom = scrollTop >= scrollHeight - clientHeight;
        const bottom = scrollHeight - clientHeight;

        // Detect if the top or bottom of the page is reached
        if (atTop && !this.topBounce) {
          // Set the toolbar as not hideable
          this.$emit('is-toolbar-hideable', false);
          this.topBounce = true;
        } else if (atBottom && !this.bottomBounce) {
          // Force the toolbar to be hidden (only for the list display)
          this.$emit('force-hidding-toolbar', true);
          // Set the toolbar as not hideable
          this.$emit('is-toolbar-hideable', false);
          this.bottomBounce = true;
        }

        // Scroll from the top: make the toolbar hideable again
        if (this.topBounce && scrollTop > 0) {
          // Set the toolbar as hideable
          this.$emit('is-toolbar-hideable', true);
          this.topBounce = false;
        }

        // Scroll from the bottom: make the toolbar hideable again
        if (this.bottomBounce && scrollTop < bottom) {
          // Stop forcing the toolbar to be hidden (only for the list display)
          this.$emit('force-hidding-toolbar', false);
          // Defer to avoid an effect when the toolbar reappears
          setTimeout(() => {
            // Set the toolbar as hideable
            this.$emit('is-toolbar-hideable', true);
          }, 0);
          this.bottomBounce = false;
        }
      }

      this.$emit('scroll', e.target.scrollTop);
      this.setShadows();
    },
    setShadows() {
      if (!this.containerIsScrolling && this.scrollBox) {
        const { scrollTop, scrollHeight, clientHeight } = this.scrollBox;
        window.requestAnimationFrame(() => {
          const shadowTop = this.container.querySelector('.shadow-top');
          if (shadowTop && scrollTop > 0) {
            shadowTop?.classList?.add('shadow-active');
          } else if (shadowTop) {
            shadowTop?.classList?.remove('shadow-active');
          }

          const shadowBottom = this.container.querySelector('.shadow-bottom');
          if (shadowBottom && (scrollHeight - Math.round(scrollTop) > clientHeight)) {
            shadowBottom?.classList?.add('shadow-active');
          } else if (shadowBottom) {
            shadowBottom?.classList?.remove('shadow-active');
          }

          this.containerIsScrolling = false;
        });
        this.containerIsScrolling = true;
      }
    },
    async scrollToPosition(position) {
      await this.$nextTick();
      this.scrollBox?.scrollTo({
        left: 0,
        top: position,
        behavior: 'smooth',
      });
    },
    getPosition() {
      return this.scrollBox ? this.scrollBox.scrollTop : 0;
    },
    checkVerticalScroll() {
      const scrollBoxWidth = this.scrollBox.offsetWidth;
      const contentWidth = this.content.offsetWidth;
      if (contentWidth - scrollBoxWidth) {
        this.$emit('update:has-vertical-scroll', true);
      } else {
        this.$emit('update:has-vertical-scroll', false);
      }
    },
    checkScrollBoxSize() {
      const { scrollHeight, clientHeight } = this.scrollBox;

      // Set shadows
      this.setShadows();

      // Check if it is scrollable
      if (scrollHeight - clientHeight > 0) {
        this.isScrollable = true;
      } else {
        this.isScrollable = false;
      }

      // Check if the toolbar is hideable
      if (scrollHeight - this.getWindowHeight >= this.getToolbarSize) {
        this.isToolbarHideable = true;
      } else {
        this.isToolbarHideable = false;
      }
      this.$emit('is-toolbar-hideable', this.isToolbarHideable);
    },
  },
};
