
// Api
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SSnackBar',
  props: {
    preset: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: true,
    },
    content: {
      type: String,
      default: '',
    },
    button: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      privateShow: false,
    };
  },
  computed: {
    ...mapGetters({
      isMedium: 'screen/isMedium',
      getCloseCallback: 'snackbar/getCloseCallback',
      getActionCallback: 'snackbar/getActionCallback',
    }),
  },
  watch: {
    async preset() {
      // If there is already a snackbar visible,
      // close it and wait for it to be reset before displaying the new one
      if (this.preset && this.show) {
        this.hideSnackbar();
        await this.resetSnackbar();
        this.showSnackbar();
      } else if (this.preset) {
        // Else, display the snackbar immediately
        this.showSnackbar();
      }
    },
    show() {
      this.privateShow = this.show;
    },
    async privateShow(newValue, oldValue) {
      // Reset the snackbar when it is automatically hidden
      if (!newValue && this.show) {
        const closeCallback = this.getCloseCallback;
        this.hideSnackbar();
        await this.resetSnackbar();
        // Process the closeCallback after the reset to avoid a conflict if the callback triggers a new snackbar
        if (closeCallback && oldValue) {
          closeCallback();
        }
      }
    },
    async $route(newRoute, oldRoute) {
      // If the snackbar is displayed, reset it when the route changes
      if (this.privateShow) {
        const newName = newRoute.name !== 'home' ? newRoute.name : 'search';
        const oldName = oldRoute.name !== 'home' ? oldRoute.name : 'search';
        // Do not reset the snackbar after login (error updating preferences)
        const redirectAfterLogin = oldName === 'Login' && oldRoute.query.redirect === newRoute.path;
        if (newName !== oldName && !redirectAfterLogin) {
          const closeCallback = this.getCloseCallback;
          this.hideSnackbar();
          await this.resetSnackbar();
          // Process the closeCallback after the reset to avoid a conflict if the callback triggers a new snackbar
          if (closeCallback) {
            closeCallback();
          }
        }
      }
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: 'snackbar/showSnackbar',
      hideSnackbar: 'snackbar/hideSnackbar',
      resetSnackbar: 'snackbar/resetSnackbar',
    }),
    async confirmAction() {
      // Reset the snackbar when the action is confirmed
      const actionCallback = this.getActionCallback;
      this.hideSnackbar();
      await this.resetSnackbar();
      // Process the actionCallback after the reset to avoid a conflict if the callback triggers a new snackbar
      if (actionCallback) {
        actionCallback();
      }
    },
  },
};
