import { render, staticRenderFns } from "./default.vue?vue&type=template&id=57e3540f"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=57e3540f&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SSideMenu: require('/src/components/SSideMenu.vue').default,SToolbar: require('/src/components/SToolbar.vue').default,SScrollBox: require('/src/components/SScrollBox.vue').default,SSnackBar: require('/src/components/SSnackBar.vue').default})
