
// Modules
import { mapGetters, mapActions } from 'vuex';

// API
import * as apiProfile from '../api/private/profile';

import errorsMixin from '../mixins/error-handler';

export default {
  name: 'SSideMenu',
  mixins: [errorsMixin],
  data() {
    return {
      timer: null,
      loaded: false,
      themeApiTimeoutId: null,
      themeLogTimeoutId: null,
      appMode: false,
    };
  },
  computed: {
    ...mapGetters({
      sideMenuItems: 'menu/getSideMenuItems',
      isSideToggled: 'menu/isSideToggled',
      getSideMenuEnabled: 'menu/getSideMenuEnabled',
      getToolbarSize: 'screen/getToolbarSize',
      getToolbarEnabled: 'menu/getToolbarEnabled',
      isXLarge: 'screen/isXLarge',
      getLargeSize: 'screen/getLargeSize',
      isStrictXLarge: 'screen/isStrictXLarge',
      user: 'firebase/user',
      getUser: 'preferences/getUser',
      getUserControl: 'syncTab/getUserControl',
      userSecured: 'preferences/userSecured',
      isAuth: 'authToken/isAuth',
    }),
    hasUserControl() {
      return this.getUserControl.active;
    },
    drawer: {
      /** @returns {Boolean} toggleSide */
      get() {
        return this.isSideToggled;
      },
      /** @param {Boolean} value */
      set(value) {
        this.setSideMenu(value);
      },
    },
    getDarkMode() {
      return this.$vuetify.theme.dark;
    },
    filteredItems() {
      const items = [];
      this.sideMenuItems.forEach((group) => {
        const filteredGroup = group.filter((item) => (this.isAuth ? item.connected : item.anonymous));
        items.push(filteredGroup);
      });
      return items;
    },
    clippedToolbar() {
      return !!this.getToolbarEnabled;
    },
    hideOverlay() {
      return this.loaded && (this.isXLarge || !this.drawer);
    },
    drawerHeight() {
      if (this.clippedToolbar) {
        return `calc(100% - ${this.getToolbarSize}px)`;
      }
      return '100%';
    },
  },
  watch: {
    isXLarge() {
      this.appMode = this.isXLarge;
    },
  },
  mounted() {
    this.appMode = this.isXLarge;
    document.addEventListener('mousemove', this.mouseTrigger);

    // Needed to solve overlay bug
    // Allow to trigger computed only when app, compo & vuex are ready
    setTimeout(() => {
      this.loaded = true;
    }, 0);
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.mouseTrigger);
  },
  methods: {
    ...mapActions({
      setSideMenu: 'menu/setSideMenu',
      setDialogAboutOpen: 'preferences/setDialogAboutOpen',
      setDialogAboutViewed: 'preferences/setDialogAboutViewed',
      updateDarkMode: 'preferences/updateDarkMode',
      setIsAuth: 'authToken/setIsAuth',
      initSnackbar: 'snackbar/initSnackbar',
    }),
    toggleDarkMode() {
      this.updateDarkMode(!this.$vuetify.theme.dark);

      if (this.$matomo) {
        // 2s timeout to prevent missclick & user who just want to test then revert
        clearTimeout(this.themeLogTimeoutId);
        this.themeLogTimeoutId = setTimeout(() => {
          this.$matomo.trackEvent('Theme', 'toggleTheme', this.$vuetify.theme.dark ? 'dark' : 'light');
        }, 2000);
      }

      if (this.userSecured && !this.hasUserControl) {
        clearTimeout(this.themeApiTimeoutId);
        this.themeApiTimeoutId = setTimeout(() => {
          apiProfile.updatePreference({ darkmode: this.$vuetify.theme.dark }, this).catch((error) => {
            // Validation error
            if (error.response && error.response.status === 400) {
              const snackbar = {
                options: {
                  color: 'error',
                },
                content: this.errors$.get('preference', 'darkmode'),
              };
              this.initSnackbar(snackbar);
            }
          });
        }, 1000);
      }
    },

    mouseTrigger(e) {
      if (!this.getSideMenuEnabled || this.drawer) {
        return;
      }

      e = e || window.event;

      if (!e.movementX || !e.movementY) {
        return;
      }

      const currentXPos = e.pageX ? e.pageX : 500;
      const currentYPos = e.pageY ? e.pageY : 500;

      if (currentXPos <= 10 && currentYPos > this.getToolbarSize) {
        this.timer = setTimeout(() => {
          this.drawer = true;
        }, 250);
      } else {
        clearTimeout(this.timer);
      }
    },
    logout() {
      if (this.userSecured) {
        this.$firebase.signOut();
      } else {
        this.setIsAuth(false);
      }
    },
    navigateTo(link) {
      const privateRoute = this.$router.options.routes.find((route) => route.name === link && route.meta && route.meta.private);
      if (privateRoute && !this.userSecured) {
        if (this.$route.name !== 'Login' || (this.$route.name === 'Login' && this.$route.query.redirect !== privateRoute.path)) {
          this.$newRouter.push({ name: 'Login', query: { redirect: privateRoute.path } });
        } else if (this.$route.query.redirect === privateRoute.path && !this.isStrictXLarge) {
          this.drawer = false;
        }
      } else {
        this.$newRouter.push({ name: link });
      }
    },
    openAbout() {
      this.setDialogAboutOpen(true);
      this.setDialogAboutViewed(true);
      this.drawer = false;
    },
  },
};
